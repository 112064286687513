import logo from './belle.png';
import './App.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Typography } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Typography variant="body1" padding={10}>
        <a
          className="App-link"
          href="https://www.instagram.com/belleinnocence/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon fontSize="medium" />
          <FacebookIcon fontSize="medium" />
          <TwitterIcon fontSize="medium" />
        </a>
        </Typography>
      </header>
    </div>
  );
}

export default App;
